<script setup lang="ts">
import { ref, computed } from 'vue'
import type { User } from '../types/account'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import TableList from '../components/TableList.vue'
import axiosClient from '../axiosClient'
import { useAccountStore } from '../stores/account'
import { PlusIcon } from '@heroicons/vue/20/solid'
import AlertDialogue from '../components/AlertDialogue.vue'

const users = ref<User[]>([])
const isLoading = ref<boolean>(true)

const inviteUserDialogue = ref<boolean>(false)

const { organization } = useAccountStore()

const inviteEmail = ref<string>("")
const inviteError = ref<string>("")
const isSending = ref<boolean>(false)

axiosClient.get('user/list').then((response) => {
  const { data } = response
  users.value = data
  isLoading.value = false
})

const link = computed(() => {
  return `${location.host}/signup?c=${organization?.invite_code}`
})

const copyLink = () => {
  navigator.clipboard.writeText(link.value)
}

const regenerateInviteCode = () => {
  axiosClient.post('/user/regenerate-code').then((res) => {
    if (organization) {
      organization.invite_code = res.data
    }
  })
}

const sendUserInvite = () => {
  isSending.value = true
  axiosClient.post('/user/invite', { email: inviteEmail.value, link: 'https://' + link.value }).then(res => {
    console.log(res)

    inviteUserDialogue.value = false
    isSending.value = false
  }).catch(err => {
    inviteError.value = err.response.data.message 
    isSending.value = false
  })
}

const cancelUserInvite = () => {
  inviteError.value = ""
  inviteEmail.value = ""
  inviteUserDialogue.value = false
}

</script>

<template>
  <div>
    <div class="md:flex md:items-center md:justify-between border-b border-airon-border pb-4">
      <div class="min-w-0 flex-1">
        <h1
          class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {{ organization?.name }}'s Users
        </h1>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0">
        <button type="button"
          class="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          @click="inviteUserDialogue = true">
          <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Invite User
        </button>
      </div>
    </div>

    <p class="max-w-lg text-sm text-black mt-5 mb-12">
      See and manage all users connected to your organisation.
    </p>
    <TableList v-if="!isLoading && users && users.length > 0">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="usr in users" :key="usr.user_id">
          <td>
            <router-link :to="`/user/${usr.user_id}`">{{ usr.email }}</router-link>
          </td>

          <td>{{ usr.name || '--' }}</td>
          <td>
            {{ usr.role_name }}
          </td>
        </tr>
      </tbody>
    </TableList>
    <SpinnerLoader v-else-if="isLoading" />
    <div v-else class="p-10">No results</div>
  </div>

  <AlertDialogue type="info" title="Invite User"
    description="Send an invite code to be used during sign up. Generating a new code will invalidate the previous one."
    :open="inviteUserDialogue" submit-text="Invite" @close="cancelUserInvite()"
    @submit="sendUserInvite(); ">
    <div class="mt-4">
      <h3 class="font-bold inline">Invite Link</h3>
      <a :href="'http://' + link" target="_blank"
        class="block w-fit hover:underline py-0.5 px-2 rounded-md border bg-gray-100">
        {{ link }}
      </a>
    </div>

    <div class="mt-4 space-x-2">
      <button type="button"
        class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        @click="copyLink()">
        Copy Link
      </button>
      <button type="button"
        class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        @click="regenerateInviteCode">
        Regenerate
      </button>
    </div>

    <div class="mt-2">
      <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
      <div>
        <input type="email" name="email" id="email"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
          placeholder="someone@example.com" aria-describedby="email-description" v-model="inviteEmail"/>
      </div>
      <p class="mt-2 text-sm text-gray-500" id="email-description">Your invite link will be sent to this email. <span v-if="isSending">Sending...</span></p>
    </div>
    <div v-if="inviteError.length > 0" class="mt-2 text-red-500 bg-red-50 p-2 rounded-md outline outline-1 outline-red-300">
      {{inviteError}}
    </div>
  </AlertDialogue>
</template>
