<script setup lang="ts">
import axiosClient from '../axiosClient'
import { ref } from 'vue'
import PopoutPanel from '../components/PopoutPanel.vue'

defineProps({
  open: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['close', 'submit'])

const newKeyName = ref('')
const newKeyValue = ref('')

const addKey = () => {
  axiosClient
    .post('/key/add', {
      name: newKeyName.value,
      key: newKeyValue.value
    })
    .then(() => {
      emit('close')
      emit('submit')
    })
    .catch((error) => {
      // TODO: error message
    })
}
</script>

<template>
  <PopoutPanel
    :open="open"
    title="Add Key"
    description="Add a new key for SSH access."
    submit-text="Add"
    @close="$emit('close')"
    @submit="addKey"
  >
    <div>
      <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
      <div class="mt-2">
        <input
          id="name"
          v-model="newKeyName"
          required="true"
          type="text"
          name="name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <div>
      <label for="key" class="block text-sm font-medium leading-6 text-gray-900">Key</label>
      <div class="mt-2">
        <textarea
          id="key"
          v-model="newKeyValue"
          required="true"
          name="key"
          rows="8"
          class="block w-full font-mono rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-xs sm:leading-4"
        />
        <span class="text-xs text-gray-500">
          Usually at ~/.ssh/id_rsa.pub, ~/.ssh/id_dsa.pub, or ~/.ssh/id_ecdsa.pub
        </span>
      </div>
    </div>
  </PopoutPanel>
</template>
